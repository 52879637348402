<template>
  <div class="terms">

    <loading :active.sync="isLoading"
             :is-full-page="fullPage"></loading>

          <div class="row">
            <div class="col-md-6">
              <div class="switch-cover">
              </div>
            </div>
            <div class="col-md-6 " >
              <div class="personal-info p-5 bg-white" >

                  <form method="post" action="#" v-on:submit.prevent="validateCheckoutForm">

                    <div class="mx-auto" style="width: 70%">

                      <div class="row">
                        <div class="col-md-6">
                            <a class="text-gray" @click="goBack"  href="#"> <img src="@/client/assets/images/left.png"> Back </a>
                        </div>
                        <div class="col-md-6">
                          <div style="float: right;">
                            Step 2 of 3 <br/> <span class="text-black text-bold"  > Forgot Password </span>
                          </div>
                        </div>
                      </div>
                      <div class="mb-5" style="clear: both;">

                      </div>

                      <div class="mb-4">
                        <h3>Check your Mail</h3>
                      </div>

                      <div class="mt-3 mb-5">
                        <h5>We,ve sent a 6-digit confirmation code to username@gmail.com. Make sure you enter correct code.</h5>
                      </div>

                      <div class="text-center mb-3 ">
                        <CodeInput  :fields="6" :loading="false" class="input " v-on:change="onChange" v-on:complete="onComplete" />
                      </div>



                      <div class="mt-5 mb-5 ">
                        <button type="submit"  class=" btn-secondary btn  btn-lg  quote-btn ">  <strong>Next </strong>  </button>
                      </div>

                      <div class="row mt-5">
                        <div class="col-md-12 mt-5">
                          <div class="text-center">
                            Didn’t recieve any email? Check in spam or <br/>
                            <a class="text-bold text-black" @click="goBack">Try another email address</a>
                          </div>
                        </div>
                      </div>

                    </div>

                  </form>






              </div>


            </div>
          </div>



  </div>
</template>

<script>

  import { required} from 'vuelidate/lib/validators'
  import { mapActions } from "vuex";
  import Loading from 'vue-loading-overlay';
  import CodeInput from "vue-verification-code-input";
  import 'vue-loading-overlay/dist/vue-loading.css';

  export default {
    name: 'home',
    data () {
      return {
        isLoading: false,
        fullPage: true,
        verificationCode:""
      };
    },
    mounted(){
      this.quotation = JSON.parse(localStorage.getItem("quote"));
    },

    components: { Loading, CodeInput },

    methods:{
      ...mapActions(["makePayment"]),
      onChange(v) {

      },

      onComplete(v) {
        this.v_code = v;
      },
      nextStep(){

        this.$router.push('/reset-password');

        /*let self = this;

        this.isLoading = true;


        this.makePayment(this.checkout).then((response) => {
          self.isLoading = false;
          self.$modal.show('stk-push-wait');

        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })*/
      },

      validateCheckoutForm(){
        this.nextStep()
      },
      goBack(){
        this.$router.push('/forgot-password');
      },

    }
  }
</script>

<style>

  table td{
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .personal-info{
    min-height: 80vh;
    background-color: #F2F2F2;
  }


  .sso-sign-up a{
    border:1px solid #BDBDBD;
    padding: 15px;
    margin: 5px;
    border-radius: 4px;
  }
  .sso-sign-up img{
      height: 30px;
      width: 30px;
  }
   .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    color: #666666;
    font-size: 16px;
    font-weight: 600;
  }
  .nav-link:hover {
    border: none;
  }

  .personal-info button{
    width: 100%;
  }


</style>
